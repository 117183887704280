#content-core .module {
  // eliminate the gab from fullwidth container
  margin-left: -15px;
  margin-right: -15px;
  background-repeat: no-repeat;
  padding-top: 70px;
  padding-bottom: 70px;
  scroll-padding: 90px 0 0 0;
  @media screen and (min-width: 768px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  @media screen and (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  &--no-pad-top {
    padding-top: 0;
  }

  &--no-pad-bot {
    padding-bottom: 1px;
  }

  &--no-pad {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__content {
    max-width: 1280px;
    margin: 0 auto;
  }

  &__header {
    margin-top: -20px;

    #titleScroller {
      font-size: 20px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      color: @primary;
      text-align: center;
      margin-top: 50px;

      svg {
        width: 40px;
        cursor: pointer;
        margin: 0 auto;
      }
    }

    @media screen and (min-width: 768px) {
      margin-top: -14px;
    }

    h1 {
      margin-bottom: 30px;
      span {
        // font-size: x-large;
        // display: block;
        color: #4d4d4d;
        // margin-bottom: 10px;
      }
    }

    .module__content {
      max-width: 937px;
      margin-top: 20px;

      @media screen and (min-width: 768px) {
        margin-top: 210px;
        margin-bottom: 120px;
      }

      .unterueberschrift {
        font-size: large;
        text-align: center;
        margin-bottom: 37px;
        margin-top: -20px;
        font-weight: normal;
      }

      .einleser {
        // color: @primary;
        font-weight: 600;
      }
    }

    &.frontpage {
      background-image: none !important;
      padding-top: 0;

      .frontbanner {
        min-width: 100%;
      }

      .module__content {
        margin-top: 40px;

        @media screen and (min-width: 768px) {
          margin-top: 80px;
        }
      }
    }
  }

  &__title {
    margin-bottom: 40px;
  }

  &--white {
    background-color: #fff;
  }

  &--grey {
    background-color: @secondary;
  }

  &--topleft {
    background-position: top left;
  }

  &--topright {
    background-position: top right;
  }

  &--topcenter {
    background-position: top center;
  }

  &--bottomleft {
    background-position: bottom left;
  }

  &--bottomright {
    background-position: bottom right;
  }

  &--bottomcenter {
    background-position: bottom center;
  }

  &--cover {
    background-size: cover;
  }

  &--topfull {
    background-size: 100%;
    background-position: top center;
  }

  &__download {
    h2.module__title {
      margin-bottom: 60px;
    }

    .module__text {
      margin-bottom: 50px;
    }
  }

  &__dreiklang {
    .card__item {
      @media screen and (min-width: 768px) {
        flex: 0 0 31%;
      }

      h2 {
        margin-bottom: 8px;
      }
    }

    .module__text {
      margin-bottom: 60px;
    }
  }

  &__imagetext {
    .module__content {
      display: flex;
      @media screen and (min-width: 768px) {
        justify-content: space-between;
      }

      h2 {
        text-align: left;
        margin-bottom: 40px;
      }

      &.imageleft,
      &.imageleftcenter {
        flex-direction: column;
        align-items: flex-start;

        @media screen and (min-width: 768px) {
          flex-direction: row-reverse;
        }

        .textwrapper {
          padding-left: 80px;
        }
      }

      &.imageright,
      &.imagerightcenter {
        flex-direction: column;
        align-items: flex-start;

        @media screen and (min-width: 768px) {
          flex-direction: row;
        }

        .textwrapper {
          padding-right: 80px;
        }
      }

      &.imageleftcenter,
      &.imagerightcenter {
        align-items: center;
      }
    }

    .textwrapper {
      flex: 1 1 50%;
    }

    .imagewrapper {
      text-align: center;
      // background-color: #fff;
      // padding: 60px;
      margin-top: 40px;
      display: flex;

      @media screen and (min-width: 768px) {
        margin-top: 0;
      }

      img {
        object-fit: contain;
        // box-shadow: @shadow;
        filter: drop-shadow(0 0 8px rgba(0 0 0 / 30%));
      }
    }
  }

  &__video {
    .youtubecontainer {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 0;
      height: 0;
      overflow: hidden;
      margin-top: 60px;

      @media screen and (min-width: 768px) {
        margin-top: 130px;
      }

      iframe,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__text {
    .button {
      margin-top: 40px;
    }
  }

  &__contact {
    .module__content {
      iframe {
        margin-top: 36px;
        max-width: 100%;
        border: 0;
      }

      .mapwrapper {
        margin-top: 60px;
      }

      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;

        &.mapleft {
          flex-direction: row-reverse;
        }

        .mapwrapper {
          margin-top: 0;
        }
      }
    }
  }

  &__news {
    .newsitem {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      margin-top: 1.4rem;
      padding-bottom: 2.5rem;
      border-radius: @radius;
      box-shadow: @shadow;
      padding: 1.875rem;
      background-color: #fff;
      gap: 1.875rem;
      @media (min-width: 992px) {
        flex-direction: row;
      }
      &__title {
        font-size: 1.8rem;
      }

      &__date {
        margin-bottom: 0;
        font-size: 1.3rem;
        + h2 {
          margin-top: 0;
        }
      }

      &__image {
        object-fit: contain;
        max-width: 12.5rem;
      }
    }
    .button {
      margin-top: 2rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
