//*// HEADER //*//
#portal-header {
  padding: 20px 0;
  margin: 0 auto 20px;
  .clearfix(); //clear floats
}
#portal-logo {
  float: left;
}

#portal-languageselector {
  margin-bottom: 0;
  display: inline-block;
  padding-left: 0;
  top: 6px;
  position: relative;
  li {
    display: inline-block;
    // margin-right: 10px;

    a {
      color: #fff;
      padding-bottom: 2px;

      &:hover {
        text-decoration: none;

        span {
          border-bottom: 2px solid rgba(255, 255, 255, 0.4);
        }
      }
    }

    &.currentLanguage a {
      font-weight: 600;
      padding-bottom: 0;
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 3px;
      // span {
      //border-bottom: 2px solid rgba(255, 255, 255, 0.4);
      //text-decoration: none;
      // }
    }

    &:first-of-type::after {
      content: "|";
      position: relative;
      top: -6px;
      // font-weight: 600;
    }
  }
}

#portal-languageselector + #portal-languageselector {
  display: none;
}

#portal-anontools {
  float: right;
  ul {
    padding-right: 0;
  }
}

#portal-membertools-wrapper {
  position: absolute;
  color: #fff;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;

  @media (min-width: 992px) {
    transform: inherit;
    left: inherit;
    top: 152px;
    right: 6px;
  }

  #user-name {
    display: none;
  }

  #membertools-personaltools-logout {
    list-style-type: none;
    a {
      font-weight: 600;
      // border: 2px solid #d41130;
      color: #fff;
      border: 2px solid #fff;
      padding: 0 0.4rem;
      border-radius: 8px;

      @media (min-width: 992px) {
        color: #d41130;
        border: 2px solid #d41130;
        background: #fff;
      }
    }
  }
}

.scroll {
  #portal-membertools-wrapper {
    @media (min-width: 992px) {
      top: 102px;
    }
  }
}

//mobile search
#portal-searchbox {
  clear: left;
  margin-left: 3px;
  margin-top: 60px;
  padding-top: 20px;
  position: relative;
  white-space: nowrap;
  // z-index: 2;
  .searchSection {
    display: none;
  } //non on mobile
  [type="submit"] {
    display: none;
  } //submit hidden on mobile
  label {
    font-size: 86.667%;
  }

  svg {
    height: 40px;
    cursor: pointer;
  }

  #searchDrop {
    background-color: #fff;
    padding: 10px;
    display: none;
    position: absolute;
    right: 0;
    top: 72px;
    z-index: 2;
    width: 407px;
    box-shadow: 2px 16px 16px 0 rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: top ease 0.2s;

    @media screen and (min-width: 992px) {
      top: 70px;
    }

    .scroll & {
      top: 62px;
      @media screen and (min-width: 992px) {
        top: 57px;
      }
    }
  }

  .searchSection {
    display: inherit;
  } //yes for non mobile
  [type="text"] {
    // border-bottom-right-radius: 0;
    // border-top-right-radius: 0;
    float: left;
    width: 19rem;
    margin-right: 1rem;
    border: 1px solid @dark;
  }
  [type="submit"] {
    display: inline-block;
    margin-left: -1px;
    vertical-align: top;
    width: auto;
    display: inline-block;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

//non mobile search
// @media (min-width: @plone-grid-float-breakpoint) {
#portal-header {
  margin-bottom: 15px;
}

#portal-searchbox {
  float: right;
  margin-left: 1em;
  margin-top: 5px; //visual balance
  max-width: 50%;
  padding-top: 0;
  position: relative;
}
// }

#global_statusmessage {
  display: none;
}
