//*// SITE NAV //*//

.plone-nav {
  margin-bottom: 0;
  padding-left: 0; // Override default ul/ol
  list-style: none;
  &:extend(.clearfix all);
  li {
    position: relative;
    display: block;
    @media (max-width:991px) {
      // border-bottom: 1px solid @primary;
      padding: 9px 10px 0;
    }
    li:last-of-type {
      @media (max-width:991px) {
        border-bottom: 0;
      }
    }
    &.has_subtree a {
      padding-right: 2.5em;
    }
    label {
      margin: 0;
    }
    a {
      position: relative;
      display: block;
      padding: @plone-sitenav-link-padding;
      color: @font-color;
      font-size: ceil((@plone-font-size-base * 1.15));;
      hyphens: auto;
      &:hover,
      &:focus {
        text-decoration: none;
        &::after {
          width: 100%;
          opacity: 1;
        }
      }
    }
    // Navigation sublevels
    ul {
      padding-left: 0.5em;
      // visibility: hidden;
      // opacity: 0;
      // height: 0;
      li {
        a {
          font-size: ceil((@plone-font-size-base * 1.1));;
          // background-color: @plone-sitenav-bg;
          // color: #fff;

        }
      }
    }
    .opener {
      display: none;
      & + label:after {
        transform: rotate(0deg);
        transition: ease-in 0.35s;
      }
      @media (max-width: 991px) {
        &:checked + label:after {
          transform: rotate(-180deg);
          transition: ease-in 0.35s;
          background: transparent !important
        }
      }
      & ~ ul {
        transition: ease-out 0.35s;
      }
      &:checked ~ ul {
        height: auto;
        transition: ease-in 0.35s;
      }
      @media (max-width: 991px) {
        &:checked ~ ul {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    label {
      // position: absolute;
      // right: 0em;
      // top: 0em;
      // height: 100%;
      // width: 4em;
      // text-align: center;
      display: none;
      // &:after {
      //   border-radius: @plone-input-border-radius;
      //   content: "▼";
      //   cursor: pointer;
      //   display: inline-block;
      //   line-height: 1;
      //   width: 1.5em;
      //   height: 1.5em;
      //   font-size: 1.25em;
      //   padding: 0.25em;
      //   right: 0.5em;
      //   top: 0.45em;
      //   position: absolute;
      // }
    }
  }

  @media (min-width: 992px) {
    li {
      label {
        top: 0em;
        width: 2em;
        &:after {
          top: 1.25em;
        }
      }
      &.has_subtree {
        .has_subtree li {
          label {
            right: 0.25em;
            &:after {
              top: 0.5em;
            }
          }

          a:after,
          &.current a:after{
            content: "";
            opacity: 0;
            width: 0;
            display: block;
            height: 3px;
          }
          &:hover a:after,
          &.current a:after{
            background-color: #fff;
            opacity: 1;
            width: 100%;
            transition: all ease 0.2s;

          }
        }
      }
      label {
        &:after {
            width: 2.0em;
            height: 2.0em;
            font-size: 0.75em;
            padding: 0.5em 1em 0.5em 0;
            background: transparent;
        }
      }
      ul {
        position: absolute;
        z-index: 100;
        padding: 0;
        opacity: 0;
        visibility: hidden;
        transition: ease-out 0.75s;
        li {
          a {
            min-width: 14em;
            display: inline-block;
            color: #fff;
          }
        }
      }
    }
    .has_subtree:hover > ul,
    .has_subtree .has_subtree:hover > ul {
      opacity: 1;
      visibility: visible;
      transition: ease-in .35s;
      // background: #fff;
      background: @primary;
    }
    .has_subtree .has_subtree ul {
      position: absolute;
      z-index: -1;
      left: 0em;
      top: 0px;

    }
    .has_subtree .has_subtree:hover > ul {
      // we only shift the submenus to the right if visible
      // to prevent horizontal scrollers if not needed
      left: 16em;
    }
  }
}

.plone-navbar {
  float: right;
  .clearfix();
  color: @font-color;
  position: relative;
  min-height: @plone-sitenav-height; // Ensure a navbar always shows (e.g., without a .navbar-brand in collapsed mode)
  // Prevent floats from breaking the navbar
  &:extend(.clearfix all);
}

.plone-navbar-header {
  .clearfix(); //without extend
  @media (min-width: 992px) {
    float: left;
  }
}

.plone-navbar-collapse {
  overflow-x: visible;
  padding-right: @plone-sitenav-padding-horizontal;
  padding-left:  @plone-sitenav-padding-horizontal;
  border-top: 1px solid transparent;
  &:extend(.clearfix all);
  -webkit-overflow-scrolling: touch;

  @media (max-width: 991px) {
    position: fixed;
    z-index: 10;
    left:-100%;
    width: 100%;
    height: 100%;
    background: #fff;
    transition: left ease .2s;
  }
  &.in {
    left: 0;
    overflow-y: scroll;
  }

  @media (min-width: 992px) {
    width: auto;
    border-top: 0;
    box-shadow: none;
    &.plone-collapse {
      display: block !important;
      height: auto !important;
      padding-bottom: 0; // Override default setting
      overflow: visible !important;
    }
    &.in {
      overflow-y: visible;
    }
    // Undo the collapse side padding for navbars with containers to ensure
    // alignment of right-aligned contents.
    .navbar-fixed-top &,
    .navbar-static-top &,
    .navbar-fixed-bottom & {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.plone-navbar-toggle {
  z-index: 1; //just in case
  position: relative;
  float: right;
  margin-top:  8px;
  margin-bottom: 6px;
  padding: 6px 10px;
  border-color: transparent;
  background-color: transparent;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214

  // We remove the `outline` here, but later compensate by attaching `:hover`
  // styles to `:focus`.
  &:focus {
    outline: 0;
    background: transparent;
    box-shadow: none;
    border-color: @primary;
  }
  &:hover {
  	background-color: transparent;
  	border-color: transparent;
    box-shadow: none;
  }

  // Burgerbar (uncomment for a ≡ menu and comment menu just below)

  .icon-bar {
    display: block;
    width: 40px;
    height: 4px;
    border-radius: 1px;
    background-color: @primary;
    margin-top: 2px;
  }
  .icon-bar + .icon-bar {
    margin-top: 7px;
  }
  .icon-bar + .icon-bar + .icon-bar {
    margin-bottom: 3px;
  }
  //end burgerbar icon-bar

  .icon-bar + .icon-bar::after {content: "";}
  //end menu icon-bar

  @media (min-width: 992px) {
    display: none;
  }
}

.plone-navbar-nav {
  margin: 0 -@plone-sitenav-padding-horizontal 0;

  > li > a {
    padding-top:    10px;
    padding-bottom: 10px;
    line-height: @plone-line-height-computed;
    @media (max-width: 991px){
      &:before {
        content: "";
        opacity: 0;
        width: 0;
        transition: all ease .2s;
        background-color: @primary;
        height: 3px;
        display: block;
      }
    }
    @media (min-width: 992px){
      &:after {
        content: "";
        opacity: 0;
        width: 0;
        transition: all ease .2s;
        background-color: @primary;
        height: 3px;
        display: block;
      }
    }
  }
  // use selected for the last item in the structure

  .current > a,
  .selected > a,
  .inPath > a {
    &,
    &:hover,
    &:focus {
      @media (max-width: 991px){
        &::before {
          width: 10px;
          height: 10px;
          opacity: 1;
          border-radius: 20px;
          position: absolute;
          left: -2px;
          top: 14px;
        }
      }
      @media (min-width: 992px){
        &:after {
          opacity: 1;
          width: 100%;
          transition: all ease .2s;
        }
      }
    }
  }

  // Uncollapse the nav
  @media (min-width: 992px) {
    // float: left;
    display: flex;
    justify-content: flex-end;
    > li {
      float: left;
      &.has_subtree a {
        padding-right: 2em;
      }
      > a {
        padding-top:    @plone-sitenav-padding-vertical;
        padding-bottom: @plone-sitenav-padding-vertical;
      }
      label {
        font-size: 1em;
        background: transparent;
      }
      li.has_subtree label:after {
        transform: rotate(-90deg);

      }
    }

    &.navbar-right:last-child {
      margin-right: -@plone-sitenav-padding-horizontal;
    }
  }
}
