//*// FONTS //*//

// 400 Regular
.font-face(
    @font-path:     'font/';
	@file-name:		'hind-v11-latin-regular';
	@font-family:	'Hind';
	@font-weight:	400;
	@font-style:	normal;
    @local:         ~"local('Hind'),";
);

.font-face(
    @font-path:     'font/';
	@file-name:		'hind-v11-latin-600';
	@font-family:	'Hind';
	@font-weight:	600;
	@font-style:	normal;
    @local:         ~"local('Hind'),";
);

.font-face(
    @font-path:     'font/';
	@file-name:		'hind-v11-latin-700';
	@font-family:	'Hind';
	@font-weight:	700;
	@font-style:	normal;
    @local:         ~"local('Hind'),";
);